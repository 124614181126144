import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useEnv } from '../../context/env.context';
import { useQuery } from 'react-query';
import globalConfig from '../../utils/config';
import { pathOr } from 'ramda';
import { isNilOrEmpty } from '@/utils/validator';

const useEnvs = (onStart = () => {}, onFinish = () => {}) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const { demandIntelService } = useEnv();

  const makeRequest = async (options) => {
    try {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently();

        options.config.headers = {
          ...options.config.headers,
          Authorization: `Bearer ${token}`,
        };
      }
      onStart();
      const response = await axios(options.config);
      onFinish();
      const { data } = response;

      return data;
    } catch (error) {
      onFinish();
      if (axios.isAxiosError(error) && error.response) {
        return error.response.data;
      }

      return error.message;
    }
  };

  const getEnvConfigs = async () => {
    const orgRequestConfig = {
      url: `${demandIntelService}${globalConfig.apiRoutes.getOrgConfig}`,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    };
    const envRequestConfig = {
      url: `${demandIntelService}${globalConfig.apiRoutes.getEnvList}`,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    };

    const responses = await Promise.all([
      makeRequest({ config: orgRequestConfig, authenticated: true }),
      makeRequest({ config: envRequestConfig, authenticated: true }),
    ])
    const orgResponse = responses[0];
    const envResponse = responses[1];

    const sortedEnvs = envResponse.data.toSorted(
      (a, b) => a.name.localeCompare(b.name)
    );

    const envOrder = pathOr(
      [],
      ['data', 'envOrder'],
      orgResponse,
    );
    if (isNilOrEmpty(envOrder)) return sortedEnvs;

    const orderedEnvs = [];
    for (const envId of envOrder) {
      const envIndex = sortedEnvs.findIndex(
        (envConfig) => envConfig.id == envId
      );
      if (envIndex === -1) continue;

      orderedEnvs.push(sortedEnvs[envIndex]);
      sortedEnvs.splice(envIndex, 1);
    }

    if (sortedEnvs.length > 0) orderedEnvs.push(...sortedEnvs);
    return orderedEnvs;
  };

  const { isLoading, data, isError, error, isFetched } = useQuery(
    'envList',
    () => {
      async function callApi() {
        return await getEnvConfigs();
      }

      return callApi();
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: 0,
    },
  );

  return {
    isLoading,
    data,
    error,
    isError,
    isFetched,
  };
};
export default useEnvs;
