(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@reduxjs/toolkit"), require("@deck.gl/core/typed"), require("@deck.gl/carto/typed"), require("@carto/react-workers"), require("@carto/react-core"));
	else if(typeof define === 'function' && define.amd)
		define(["@reduxjs/toolkit", "@deck.gl/core/typed", "@deck.gl/carto/typed", "@carto/react-workers", "@carto/react-core"], factory);
	else if(typeof exports === 'object')
		exports["cartoReactRedux"] = factory(require("@reduxjs/toolkit"), require("@deck.gl/core/typed"), require("@deck.gl/carto/typed"), require("@carto/react-workers"), require("@carto/react-core"));
	else
		root["cartoReactRedux"] = factory(root["@reduxjs/toolkit"], root["@deck.gl/core/typed"], root["@deck.gl/carto/typed"], root["@carto/react-workers"], root["@carto/react-core"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__106__, __WEBPACK_EXTERNAL_MODULE__332__, __WEBPACK_EXTERNAL_MODULE__385__, __WEBPACK_EXTERNAL_MODULE__116__, __WEBPACK_EXTERNAL_MODULE__485__) => {
return 