import React from 'react';
import Title from 'antd/lib/typography/Title';

function capitalizeFirstLetter(input) {
  return !input ? '' : input.charAt(0).toUpperCase() + input.slice(1);
}

export const Error = (params) => {
  return (
    <div>
      <Title level={1}>Error!</Title>
      <Title level={2}>{capitalizeFirstLetter(params.title)}</Title>
      <Title level={5}>{params.description}</Title>
    </div>
  );
};
