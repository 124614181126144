import React from 'react';
import { Tooltip } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import AddButton from './AddButton';

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <Tooltip title='Log out'>
      <div>
        <AddButton
          type='logout'
          onClick={() =>
            logout({
              returnTo: window.location.origin,
            })
          }
        ></AddButton>
      </div>
    </Tooltip>
  );
};

export default LogoutButton;
