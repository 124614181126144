import React from 'react';
import GridLoader from 'react-spinners/GridLoader';

export const Loader = () => {
  return (
    <div
      alt='Loading...'
      id='container-for-loading-animation'
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <GridLoader size='30px' color='cornflowerblue' />
    </div>
  );
};
