import { Fragment, useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { pathOr, has } from 'ramda';
import { useSelector } from 'react-redux';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import LayersIcon from '@mui/icons-material/Layers';
import Collapse from '@mui/material/Collapse';
import { Tooltip, makeStyles, Switch, useTheme } from '@material-ui/core';

// utils
import { isArrayNotEmpty, isNilOrEmpty } from '@/utils/validator';

const LegendWrapperContainer = styled.div`
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
  .toggle-loading {
    position: relative;
  }
  .toggle-loading .list-item-toggle-button {
    visibility: hidden;
  }
  .toggle-loading::after {
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 2;
  }
  .toggle-loading::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    right: 12%;
    border-radius: 50%;
    border: 2px solid ${(props) => props.theme.palette.grey[100]};
    border-top-color: ${(props) => props.theme.palette.primary.main};
    animation: spinner 0.6s linear infinite;
    z-index: 3;
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track,
  .MuiSwitch-colorSecondary.Mui-checked .MuiSwitch-thumb {
    background-color: ${(props) => props.theme.palette.primary.main};
  }
`;

// style
const useStyles = makeStyles((theme) => ({
  centeredItems: {
    justifyContent: 'center',
  },
  legendWrapperInnerContainer: {
    overflow: 'auto',
    maxHeight: 'max(250px, 60vh)',
    background: theme.palette.common.white,
    borderRadius: '4px 4px 0 0',
  },
  listItem: {
    padding: '10px 10px 10px 20px',
    '&.toggleButtonListContainer': {
      minWidth: '300px',
    },
  },
  listItemButton: {
    cursor: 'default',
    '&:hover': {
      background: 'transparent',
    },
  },
  iconCls: {
    width: '24px !important',
    height: '24px !important',
    fill: 'rgba(0, 0, 0, 0.54) !important',
  },
}));

// toggle layers button
const ToggleLayersButton = ({
  toggleButtonLabel,
  handleToggleButton,
  toggleButtonRef,
}) => {
  const classes = useStyles();
  return (
    <List
      className={classNames(classes.listItem, 'toggleButtonListContainer')}
      ref={toggleButtonRef}
    >
      <ListItem disablePadding>
        <ListItemButton onClick={handleToggleButton}>
          <ListItemText
            primary={toggleButtonLabel}
            primaryTypographyProps={{ fontSize: '1rem' }}
          />
          <ListItemIcon className={classes.centeredItems}>
            <LayersIcon className={classes.iconCls} />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
    </List>
  );
};

// legend wrapper
const LegendWrapper = ({
  layersOrder,
  legendLayerConfig = [],
  className,
  onLegendWrapperLayerChange,
  tooltipLabel = 'Show layer',
  toggleButtonLabel = 'Layers',
}) => {
  const [open, setOpen] = useState(true);
  const classes = useStyles();
  const theme = useTheme();
  const cartoLayers = useSelector((state) => state.carto.layers);
  const loadedLayers = useSelector((state) => state.app.loadedLayers);
  const toggleButtonRef = useRef();
  const listItemsRefs = useRef({});

  const TOGGLE_LOADING_CLS = 'toggle-loading';
  const DISABLED_LIST_ITEM = 'Mui-disabled';

  // remap all layers into on single one
  let combinedAllLayers = [...legendLayerConfig];
  if (!isNilOrEmpty(cartoLayers)) {
    const cartoLayersToArr = Object.values(cartoLayers).map((cartoLayer) => ({
      id: cartoLayer?.id,
      name: cartoLayer?.title,
      visible: cartoLayer?.visible,
    }));
    if (isArrayNotEmpty(cartoLayersToArr)) {
      combinedAllLayers = [
        ...legendLayerConfig.map((layer) => ({
          ...layer,
          isCartoLayer: false,
        })),
        ...cartoLayersToArr.map((cartoLayer) => ({
          ...cartoLayer,
          isCartoLayer: true,
        })),
      ];
    }
  }
  if (isArrayNotEmpty(layersOrder)) {
    combinedAllLayers = layersOrder
      .map((layerOrder) => {
        const idFromCombined = combinedAllLayers.find(
          (combinedLayer) => combinedLayer?.id === layerOrder,
        );
        if (!isNilOrEmpty(idFromCombined)) {
          return idFromCombined;
        }
        return null;
      })
      .filter((layerOrder) => layerOrder)
      .reverse();
  }

  // handles the switch change event
  const handleChange = (layer) => {
    if (!isNilOrEmpty(layer)) {
      const id = pathOr(null, ['id'], layer);
      const visible = pathOr(null, ['visible'], layer);

      if (!isNilOrEmpty(id) && !isNilOrEmpty(visible) && visible) {
        const listItem = getListItemById(id);
        if (!isNilOrEmpty(listItem)) {
          toggleDisableForListItem(listItem, true);
        }
      }
      onLegendWrapperLayerChange(layer);
    }
  };

  // handle the toggle button click event
  const handleToggleButton = () => {
    setOpen(!open);
  };

  // toggles the list item disabled class
  const toggleDisableForListItem = (listItem, status) => {
    if (status) {
      listItem.classList.add(DISABLED_LIST_ITEM);
      listItem.classList.add(TOGGLE_LOADING_CLS);
      return;
    }
    listItem.classList.remove(DISABLED_LIST_ITEM);
    listItem.classList.remove(TOGGLE_LOADING_CLS);
  };

  // gets the list item by data id
  const getListItemById = (id) => {
    const listItemsRefsObject = pathOr({}, ['current'], listItemsRefs);
    if (!isNilOrEmpty(listItemsRefsObject)) {
      const listItemsRefsArr = Object.values(listItemsRefsObject);
      if (isArrayNotEmpty(listItemsRefsArr)) {
        for (const listItem of listItemsRefsArr) {
          if (!isNilOrEmpty(listItem)) {
            const dataId = listItem.getAttribute('data-id');
            if (!isNilOrEmpty(dataId) && dataId === id) {
              return listItem;
            }
          }
        }
      }
    }
    return null;
  };

  useEffect(() => {
    let timer;
    if (isArrayNotEmpty(loadedLayers)) {
      loadedLayers.forEach((loadedLayer) => {
        if (has('id', loadedLayer)) {
          const id = pathOr(null, ['id'], loadedLayer);
          const layer = getListItemById(id);
          if (
            !isNilOrEmpty(layer) &&
            layer.classList.contains(TOGGLE_LOADING_CLS)
          ) {
            // add a delay for faster cartoLayers
            timer = setTimeout(() => {
              // enable the collapse button if the cartoLayers are done loading
              toggleDisableForListItem(layer, false);
            }, 500);
          }
        }
      });
    }
    return () => clearTimeout(timer);
  }, [loadedLayers]);

  return (
    isArrayNotEmpty(combinedAllLayers) && (
      <LegendWrapperContainer theme={theme}>
        <Paper className={className} theme={theme}>
          <div className={classes.legendWrapperInnerContainer}>
            <Collapse in={open} timeout='auto'>
              <List>
                {combinedAllLayers.map((legendLayer, index) => {
                  const lastLayer = index === combinedAllLayers.length - 1;
                  const id = pathOr(null, ['id'], legendLayer);
                  const name = pathOr(null, ['name'], legendLayer);
                  const visible = pathOr(false, ['visible'], legendLayer);
                  const isCartoLayer = pathOr(
                    false,
                    ['isCartoLayer'],
                    legendLayer,
                  );
                  return (
                    <Fragment key={id}>
                      <ListItem
                        disablePadding
                        className={classes.listItem}
                        data-id={id}
                        ref={(element) =>
                          (listItemsRefs.current[index] = element)
                        }
                      >
                        <ListItemButton className={classes.listItemButton}>
                          <ListItemText
                            primary={name}
                            primaryTypographyProps={{ fontSize: '1rem' }}
                          />
                          <ListItemIcon className={classes.centeredItems}>
                            <Tooltip title={tooltipLabel} arrow placement='top'>
                              <Switch
                                checked={visible}
                                className='list-item-toggle-button'
                                onChange={(event) => {
                                  handleChange({
                                    ...legendLayer,
                                    visible: event.target.checked,
                                    isCartoLayer,
                                  });
                                }}
                              />
                            </Tooltip>
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                      {!lastLayer && <Divider />}
                    </Fragment>
                  );
                })}
              </List>
            </Collapse>
          </div>
          <Divider />
          <ToggleLayersButton
            toggleButtonLabel={toggleButtonLabel}
            handleToggleButton={handleToggleButton}
            toggleButtonRef={toggleButtonRef}
          />
        </Paper>
      </LegendWrapperContainer>
    )
  );
};

export default LegendWrapper;
