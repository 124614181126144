import { BitmapLayer } from '@deck.gl/layers';
import { TileLayer } from '@deck.gl/geo-layers';
import { MAX_ZOOM_LEVEL } from '@/utils/constants';

// TileLayer componenet
const TileLayerComponent = (url = '', viewState = {}) => {
  return new TileLayer({
    data: url,
    minZoom: 0,
    maxZoom: MAX_ZOOM_LEVEL,
    ...viewState,

    renderSubLayers: (props) => {
      const {
        bbox: { west, south, east, north },
      } = props.tile;
      return new BitmapLayer(props, {
        data: null,
        image: props.data,
        bounds: [west, south, east, north],
      });
    },
  });
};
export default TileLayerComponent;
