import React, { useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { Dropdown, Menu, Modal } from 'antd';
import StyledButton from '../Buttons/StyledAntdButton';
import styled from 'styled-components';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import globalConfig from '../../utils/config';
import { useEnv } from '../../context/env.context';
import { useSession } from '../../context/session';
import GlobalLoading from '../GlobalLoading';
import useGuards from '../../hooks/useGuards';
import { views } from '../../constants/env';
import { setProjectView, setProjectViewConfig } from '../../store/appSlice';

// constants
import { TEMPLATE_LAYERS } from '@/utils/constants';

const SaveButton = styled(StyledButton)`
  &:focus,
  &:hover {
    color: #fff;
    background-color: #1a60b4;
  }
`;

const getMapConfig = (state) => {
  const cartoLayers = state.app.layerConfigs.order
    .map((layerId) => {
      return (
        state.carto.layers[layerId] && cleanLayer(state.carto.layers[layerId])
      );
    })
    .filter((layer) => layer);
  const customLayers = state.app.customLegendLayers.map((layer) => ({
    id: layer?.id,
    visible: layer?.visible,
    switchable: true,
    ...(TEMPLATE_LAYERS.includes(layer?.layerType) && {
      currentTimestampID: layer?.currentTimestampID,
    }),
  }));
  return {
    _for: 'carto',
    version: '0.0.0',
    basemap: state.carto.basemap,
    viewState: state.carto.viewState,
    dataSources: Object.values(state.carto.dataSources).map((dataSource) =>
      cleanDatasource(dataSource),
    ),
    layers: [...cartoLayers, ...customLayers],
  };
};

const cleanDatasource = (datasource) => {
  const cleaned = new Map();
  for (const [k, v] of Object.entries(datasource)) {
    if (k === 'credentials') continue;
    cleaned.set(k, v);
  }
  return Object.fromEntries(cleaned);
};

const cleanLayer = (layer) => {
  const cleaned = new Map();
  for (const [k, v] of Object.entries(layer)) {
    switch (k) {
      case 'id':
      case 'switchable':
      case 'visible':
        cleaned.set(k, v);
        break;
    }
  }
  return Object.fromEntries(cleaned);
};

const SaveProject = () => {
  const [state, currentEnv, projectView, projectViewConfig] = useSelector(
    (state) => [
      state,
      state.app.currentEnv,
      state.app.projectView,
      state.app.projectViewConfig,
    ],
  );
  const dispatch = useDispatch();
  const { demandIntelService } = useEnv();
  const { getBearerToken } = useSession();
  const { hasPermission } = useGuards();
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [confirmModalDetails, setConfirmModalDetails] = useState({});

  const canUpdateMyProject = hasPermission('di:MyProject:update');
  const canUpdateTeamProject = hasPermission('di:TeamProject:update');

  const saveView = async () => {
    const saveToViewId = confirmModalDetails.key;
    const payload = {
      datasetIds: [],
      datasets: [],
      metadata: getMapConfig(state),
    };
    const url = `${demandIntelService}${globalConfig.apiRoutes.postProjectView(
      currentEnv,
      saveToViewId,
    )}`;

    if (projectViewConfig.version !== undefined) {
      payload.version = projectViewConfig.version;
      if (saveToViewId === views.MY_VIEW) {
        payload.majorVersion = projectViewConfig.majorVersion;
      }
    }

    // api call
    try {
      const request = {
        url: url,
        method: 'POST',
        data: payload,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await getBearerToken()}`,
        },
      };

      const response = await axios(request);

      // don't refresh view config if what got saved isn't the current view
      if (saveToViewId === projectView) {
        dispatch(
          setProjectViewConfig({
            ...projectViewConfig,
            projectId: currentEnv,
            viewId: saveToViewId,
            majorVersion: response.data.data.majorVersion,
            minorVersion: response.data.data.minorVersion,
            version: response.data.data.version,
            // set the coord to the current from carto state, so we remain on the same
            // lat, long position
            mapConfig: {
              ...projectViewConfig.mapConfig,
              viewState: {
                ...state.carto.viewState,
              },
            },
          }),
        );
      }
    } catch (e) {
      console.error(e);
      message.error(
        `Error saving this view as "${confirmModalDetails.type}". Please try again`,
      );
    }
  };

  const replaceMyViewWithTeamView = async () => {
    const url = `${demandIntelService}${globalConfig.apiRoutes.postReplaceMyView(
      currentEnv,
    )}`;

    // api call
    try {
      const request = {
        url: url,
        method: 'POST',
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await getBearerToken()}`,
        },
      };

      await axios(request);
    } catch (e) {
      console.error(e);
      message.error('Error saving "team view" as "my view". Please try again');
    }
  };

  const doSaveAction = async () => {
    setIsLoading(true);

    try {
      if (confirmModalDetails.key === views.TEAM_VIEW_AS_MY_VIEW) {
        await replaceMyViewWithTeamView();

        // clear current project view so that the default happens
        // default assumes "my view" and loads that
        dispatch(setProjectView(null));
      } else {
        await saveView();
      }
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
    setConfirmModalDetails((confirmModalDetails) => ({ ...{} }));
  };

  const handleSaveAction = (e) => {
    const newModalDetails = { key: e.key, visible: true };
    switch (e.key) {
      case views.MY_VIEW:
        newModalDetails.title = 'Save for me';
        newModalDetails.type = 'my view';
        newModalDetails.body = [
          'The changes in this view will be saved for you only and can be accessed by selecting "my view" in the top left.',
        ];
        break;
      case views.TEAM_VIEW:
        newModalDetails.title = 'Save for team';
        newModalDetails.type = 'team view';
        newModalDetails.body = [
          'The changes in this view will be saved for everyone in this Project and can be seen by selecting "team view" in the top left.',
          'This will not affect the personal view of your team members.',
        ];
        break;
      case views.TEAM_VIEW_AS_MY_VIEW:
        newModalDetails.title = 'Save team view as my view';
        newModalDetails.body = [
          'The current "team view" will overwrite "my view". Any personal changes will be overwritten. This action cannot be undone.',
        ];
        break;
      default:
        throw Error(`Unknown save action: ${e.key}`);
    }

    setConfirmModalDetails((confirmModalDetails) => ({ ...newModalDetails }));
  };

  const buildMenuItems = () => {
    const menuItems = [];

    if (canUpdateMyProject) {
      menuItems.push({
        label: 'Save for me',
        key: views.MY_VIEW,
      });
    }

    if (canUpdateTeamProject) {
      menuItems.push({
        label: 'Save for team',
        key: views.TEAM_VIEW,
      });
    }

    return menuItems;
  };

  if (!(canUpdateMyProject || canUpdateTeamProject)) {
    return <></>;
  }
  const menuProps = {
    items: buildMenuItems(),
    onClick: handleSaveAction,
    selectable: false,
  };
  return (
    <>
      <Dropdown
        trigger={['click']}
        open={isDropdownVisible}
        menu={menuProps}
        style={{
          width: 120,
        }}
      >
        <SaveButton
          type={'primary'}
          onClick={(e) => {
            const newState = !isDropdownVisible;
            if (newState) {
              setIsDropdownVisible(newState);
            } else {
              e.target.blur();
            }
          }}
          onBlur={(e) => {
            setIsDropdownVisible(false);
            e.relatedTarget?.click();
          }}
        >
          Save
          <DownOutlined />
        </SaveButton>
      </Dropdown>

      <Modal
        title={`Confirm changes: ${confirmModalDetails.title}`}
        open={
          confirmModalDetails.visible !== undefined
            ? confirmModalDetails.visible
            : false
        }
        onOk={doSaveAction}
        onCancel={() => {
          setConfirmModalDetails((confirmModalDetails) => ({ ...{} }));
        }}
      >
        {confirmModalDetails.body?.map((line, idx) => {
          return <p key={`line-${idx}`}>{line}</p>;
        })}
      </Modal>

      {isLoading && <GlobalLoading />}
    </>
  );
};

export default SaveProject;
