import React from 'react';
import { SvgIcon } from '@mui/material';

export default function LassoIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d='M12 3c4.935 0 9 3.736 9 9l-.002.343-.012.669c-.012.437-.033.86-.062 1.265l-.05.597C20.498 18.767 19.267 21 17 21c-1.192 0-1.971-.341-2.987-1.122l-.473-.375c-.401-.319-.64-.473-.888-.566a4.894 4.894 0 0 0-.415-.13l-.34-.085-.398-.086-.456-.086-.66-.111-1.708-.273a9.103 9.103 0 0 1-.952-.206C5.46 17.301 3 14.954 3 12.015c0-1.508.485-2.995 1.436-4.458.355.585.906 1.04 1.562 1.272C5.328 9.916 5 10.977 5 12.015c0 1.889 1.78 3.588 3.282 4.025l.085.023.345.076.517.092 1.619.257.583.1.518.099.237.05.433.102c.272.07.512.143.73.224.434.161.783.373 1.235.718l.457.362c.806.646 1.24.857 1.959.857.893 0 1.63-1.518 1.895-4.45l.045-.585c.013-.2.024-.407.033-.62l.02-.655c.005-.224.007-.454.007-.69 0-4.12-3.134-7-7-7a1 1 0 0 1 0-2zM7 4a2 2 0 1 1 0 4 2 2 0 0 1 0-4z' />
    </SvgIcon>
  );
}
