(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@deck.gl/google-maps/typed"), require("@carto/react-core"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "@deck.gl/google-maps/typed", "@carto/react-core"], factory);
	else if(typeof exports === 'object')
		exports["cartoReactBasemaps"] = factory(require("react"), require("@deck.gl/google-maps/typed"), require("@carto/react-core"));
	else
		root["cartoReactBasemaps"] = factory(root["react"], root["@deck.gl/google-maps/typed"], root["@carto/react-core"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__168__, __WEBPACK_EXTERNAL_MODULE__485__) => {
return 