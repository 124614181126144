import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useEnv } from '../../context/env.context';
import { useQuery } from 'react-query';
import globalConfig from '../../utils/config';

const useOrganizationLogo = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const { demandIntelService } = useEnv();

  const makeRequest = async (options) => {
    try {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently();

        options.config.headers = {
          ...options.config.headers,
          Authorization: `Bearer ${token}`,
        };
      }
      const response = await axios(options.config);

      const { data } = response;

      return data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return error.response.data;
      }

      return error.message;
    }
  };

  const getDatabaseLists = async () => {
    const config = {
      url: `${demandIntelService}${globalConfig.apiRoutes.getOrganizationLogo}`,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    };

    const response = await makeRequest({ config, authenticated: true });
    return 'data:image/png;base64, ' + response;
  };

  const { isLoading, data, isError, error, isFetched } = useQuery(
    'organizationLogo',
    () => {
      async function callApi() {
        return await getDatabaseLists();
      }

      return callApi();
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  return {
    isLoading,
    data,
    error,
    isError,
    isFetched,
  };
};

export default useOrganizationLogo;
