import { createSlice, current } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import cloneDeep from 'lodash.clonedeep';

// utils
import { pathOr } from 'ramda';
import { isArrayNotEmpty, isNilOrEmpty } from '@/utils/validator';

const initialState = {
  error: null,
  currentEnv: null,
  projectView: null,
  projectViewConfig: {},
  layerConfigs: [],
  customLegendLayers: [],
  customBasemapStyles: [],
  loadedLayers: [],
  defaultLayersOrder: [],
  customLayersInfo: [],
};

const slice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    setError: (state, action) => {
      state.error = action.payload;
    },
    setCurrentEnv: (state, action) => {
      state.currentEnv = action.payload;
    },
    setProjectView: (state, action) => {
      state.projectView = action.payload;
    },
    setProjectViewConfig: (state, action) => {
      state.projectViewConfig = cloneDeep(action.payload);
      state.projectViewConfig.key = `${state.projectViewConfig.projectId}:${state.projectViewConfig.viewId}`;
    },
    setLayerConfigs: (state, action) => {
      state.layerConfigs = action.payload;
    },
    setCustomLegendLayers: (state, action) => {
      const customLegendLayers = current(state)?.customLegendLayers || [];
      if (
        !isArrayNotEmpty(customLegendLayers) &&
        isArrayNotEmpty(action.payload)
      ) {
        state.customLegendLayers = action.payload;
        return;
      }
      state.customLegendLayers = customLegendLayers.map((customLegendLayer) =>
        customLegendLayer?.id === action.payload?.id
          ? action.payload
          : customLegendLayer,
      );
    },
    modifyCustomLegendLayersProps: (state, action) => {
      const customLegendLayers = current(state)?.customLegendLayers || [];
      if (
        isArrayNotEmpty(customLegendLayers) &&
        !isNilOrEmpty(action.payload)
      ) {
        const layerId = pathOr(null, ['layerId'], action.payload);
        const propsToBeChanged = pathOr(
          [],
          ['propsToBeChanged'],
          action.payload,
        );
        const newProps = [];
        for (const propToBeChanged of propsToBeChanged) {
          const propValue = pathOr(null, ['propValue'], propToBeChanged);
          const propName = pathOr(null, ['propName'], propToBeChanged);
          if (
            !isNilOrEmpty(layerId) &&
            !isNilOrEmpty(propName) &&
            !isNilOrEmpty(propValue)
          ) {
            newProps.push({
              [propName]: propValue,
            });
          }
        }
        if (isArrayNotEmpty(newProps)) {
          const searchedCustomLegendLayer = customLegendLayers.find(
            (layer) => layer?.id === layerId,
          );
          const customLegendLayerObj = {};
          for (const prop of newProps) {
            Object.assign(customLegendLayerObj, { ...prop });
          }
          state.customLegendLayers = customLegendLayers.map(
            (customLegendLayer) =>
              customLegendLayer?.id === layerId
                ? { ...searchedCustomLegendLayer, ...customLegendLayerObj }
                : customLegendLayer,
          );
        }
      }
    },
    setCustomBasemapStyles: (state, action) => {
      state.customBasemapStyles = action.payload;
    },
    setCustomLayersInfo: (state, action) => {
      if (!isNilOrEmpty(action.payload)) {
        let customLayersInfo = current(state)?.customLayersInfo || [];
        const isPayloadInsideState = customLayersInfo.find(
          (layer) => layer?.layerId === action.payload?.layerId,
        );
        if (!isPayloadInsideState) {
          state.customLayersInfo.push(action.payload);
        }
        customLayersInfo = current(state)?.customLayersInfo || [];
        state.customLayersInfo = customLayersInfo.map((layer) =>
          layer?.layerId === action.payload?.layerId ? action.payload : layer,
        );
      }
    },
    addLoadedLayers: (state, action) => {
      state.loadedLayers = action.payload;
    },
    setDefaultLayersOrder: (state, action) => {
      state.defaultLayersOrder = action.payload;
    },
  },
});

export default slice.reducer;

export const appPersistConfig = {
  key: 'app',
  storage: storage,
  whitelist: ['currentEnv'],
};

export const setError = (payload) => ({ type: 'app/setError', payload });
export const setCurrentEnv = (envId) => ({
  type: 'app/setCurrentEnv',
  payload: envId,
});
export const setProjectView = (view) => ({
  type: 'app/setProjectView',
  payload: view,
});
export const setProjectViewConfig = (config) => ({
  type: 'app/setProjectViewConfig',
  payload: config,
});
export const setLayerConfigs = (config) => ({
  type: 'app/setLayerConfigs',
  payload: config,
});
export const setCustomLegendLayers = (config) => ({
  type: 'app/setCustomLegendLayers',
  payload: config,
});
export const setCustomBasemapStyles = (config) => ({
  type: 'app/setCustomBasemapStyles',
  payload: config,
});
export const addLoadedLayers = (config) => ({
  type: 'app/addLoadedLayers',
  payload: config,
});
export const modifyCustomLegendLayersProps = (config) => ({
  type: 'app/modifyCustomLegendLayersProps',
  payload: config,
});
export const setCustomLayersInfo = (config) => ({
  type: 'app/setCustomLayersInfo',
  payload: config,
});
export const setDefaultLayersOrder = (config) => ({
  type: 'app/setDefaultLayersOrder',
  payload: config,
});
export const reset = () => ({ type: 'app/reset' });
