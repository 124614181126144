// Basemap selector component, used for changing between mapstyles
import { useState } from 'react';
import classNames from 'classnames';
import { has } from 'ramda';

// basemap-config defaults
import {
  DEFAULT_MAP_STYLE,
  DEFAULT_MAP_TYPE,
  MAP_STYLES,
  DEFAULT_BASEMAP_ICON,
} from '@/utils/basemap-config';
// utils
import { isArrayNotEmpty } from '@/utils/validator';
import {
  Grid,
  IconButton,
  Popover,
  Typography,
  makeStyles,
} from '@material-ui/core';
// icons
import CloseIcon from '@mui/icons-material/Close';
import QueueIcon from '@mui/icons-material/Queue';

// style
const useStyles = makeStyles((theme) => ({
  baseMapContainer: {
    width: '80px',
    height: '80px',
    backgroundColor: theme.palette.common.black,
    boxShadow:
      '0 1px 2px rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15)',
    position: 'absolute',
    top: '32px',
    left: '32px',
    cursor: 'pointer',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    borderRadius: '4px',
    border: `solid 3px ${theme.palette.common.white}`,
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '& div': {
      color: theme.palette.common.white,
      zIndex: 1,
      textTransform: 'uppercase',
      fontSize: '11px',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(0,0,0,.5)',
      borderRadius: 0,
    },
  },
  stylesPopOverContainer: {
    marginLeft: '10px',
  },
  stylesPopOverGrid: {
    minHeight: '80px',
    padding: '10px',
  },
  stylesPopOverGridItem: {
    cursor: 'pointer',
    minWidth: '70px',
    height: '60px',
    fontSize: '11px',
    '&:hover span': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
    '&:hover $gridImageContainer': {
      '&:after': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  gridImageContainer: {
    backgroundColor: theme.palette.grey[100],
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '36px',
    borderRadius: '4px',
    width: '36px',
    position: 'relative',
    marginBottom: '5px',
    '& svg': {
      fill: theme.palette.grey[700],
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      borderRadius: '4px',
      right: 0,
      bottom: 0,
      border: 'solid 2px transparent',
    },
  },
  noCursor: {
    cursor: 'default',
  },
  styleContainer: {
    '& +$styleContainer': {
      marginTop: '10px',
    },
  },
}));

// popover components

// the button that show the rest of the components
const MoreButton = ({ showMoreStyles }) => {
  const classes = useStyles();
  const [isClicked, setIsClicked] = useState(false);
  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      onClick={() => {
        setIsClicked(!isClicked);
        showMoreStyles();
      }}
      className={classNames(classes.stylesPopOverGridItem, {
        [classes.noCursor]: isClicked,
      })}
    >
      {!isClicked ? (
        <>
          <Grid
            justifyContent='center'
            alignItems='center'
            container
            className={classes.gridImageContainer}
          >
            <QueueIcon />
          </Grid>
          <Grid item>
            <Typography variant='inherit'>More</Typography>
          </Grid>
        </>
      ) : (
        <Grid container justifyContent='flex-end' alignItems='flex-end'>
          <IconButton aria-label='close' size='small'>
            <CloseIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

// a list component of styles mapbox etc
const Styles = ({
  currentBasemapStyleId,
  styleTypeId,
  onBasemapStyleChange,
  visibility = true,
  onBasemapPopoverClose = () => {},
  filteredBasemapStyles = [],
}) => {
  const classes = useStyles();
  let filteredMapStyles = filteredBasemapStyles.filter(
    (style) => styleTypeId === style.type && style.id !== currentBasemapStyleId,
  );
  return (
    visibility && (
      <Grid container className={classes.styleContainer}>
        {filteredMapStyles.map((style) => (
          <Grid
            item
            key={style?.id}
            className={classes.stylesPopOverGridItem}
            onClick={() => {
              onBasemapStyleChange(style?.id, filteredMapStyles);
              onBasemapPopoverClose();
            }}
          >
            <Grid container direction='column' alignItems='center'>
              <Grid item>
                <div
                  className={classes.gridImageContainer}
                  style={{
                    backgroundImage: `url(${style?.icon})`,
                  }}
                ></div>
              </Grid>
              <Grid item>
                <Typography variant='inherit'>{style?.label}</Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    )
  );
};

// the popover component with the styles
const PopoverStyles = (props) => {
  const {
    anchorEl,
    open,
    onBasemapPopoverClose = () => {},
    currentBasemapStyles = [],
    onBasemapStyleChange,
    currentBasemapStyleId,
    filteredBasemapStyles = [],
  } = props;
  const classes = useStyles();
  const [visibilityForMore, setVisbilityForMore] = useState(false);
  const onShowMoreStyles = () => {
    setVisbilityForMore(!visibilityForMore);
  };
  return (
    <Popover
      className={classes.stylesPopOverContainer}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      anchorEl={anchorEl}
      onClose={onBasemapPopoverClose}
      open={open}
    >
      {isArrayNotEmpty(currentBasemapStyles) && (
        <Grid
          className={classes.stylesPopOverGrid}
          container
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <Grid item>
            {currentBasemapStyles.map((styleTypeId, keyId) => (
              <Styles
                key={styleTypeId}
                currentBasemapStyleId={currentBasemapStyleId}
                styleTypeId={styleTypeId}
                onBasemapStyleChange={onBasemapStyleChange}
                onBasemapPopoverClose={onBasemapPopoverClose}
                {...(keyId > 0 && { visibility: visibilityForMore })}
                filteredBasemapStyles={filteredBasemapStyles}
              />
            ))}
          </Grid>
          {currentBasemapStyles.length > 1 && (
            <Grid item>
              <MoreButton showMoreStyles={onShowMoreStyles} />
            </Grid>
          )}
        </Grid>
      )}
    </Popover>
  );
};

// Basemap selector default component
const Basemap = ({
  currentBasemapStyles = [DEFAULT_MAP_TYPE],
  currentBasemapStyleId = DEFAULT_MAP_STYLE,
  onBasemapStyleChange = () => {},
  customBasemapStyles = [],
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [filteredBasemapStyles, setFilteredBasemapStyles] = useState([
    ...MAP_STYLES,
    ...customBasemapStyles,
  ]);

  const handleBaseMapClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleBasemapPopoverClose = () => {
    setAnchorEl(null);
  };

  const isPopoverOpen = Boolean(anchorEl);

  const fetchPropFromMapStyles = (id = DEFAULT_MAP_STYLE, propName = '') => {
    const idOfMapStyle = id ?? DEFAULT_MAP_STYLE;
    const foundMapStyle = filteredBasemapStyles.find(
      (style) => style.id === idOfMapStyle,
    );
    if (has(propName, foundMapStyle)) {
      return foundMapStyle[propName];
    }
    return null;
  };

  const iconBaseMapStyle = currentBasemapStyleId
    ? fetchPropFromMapStyles(currentBasemapStyleId, 'icon')
    : fetchPropFromMapStyles(DEFAULT_BASEMAP_ICON, 'icon');

  const labelBaseMapStyle = currentBasemapStyleId
    ? fetchPropFromMapStyles(currentBasemapStyleId, 'label')
    : fetchPropFromMapStyles(null, 'label');

  return (
    <>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        className={classes.baseMapContainer}
        onClick={handleBaseMapClick}
        style={{
          backgroundImage: `url(${iconBaseMapStyle})`,
        }}
      >
        <Typography component='div' align='center' variant='body2'>
          {labelBaseMapStyle}
        </Typography>
      </Grid>
      {isPopoverOpen && (
        <PopoverStyles
          anchorEl={anchorEl}
          open={isPopoverOpen}
          onBasemapPopoverClose={handleBasemapPopoverClose}
          currentBasemapStyles={currentBasemapStyles}
          onBasemapStyleChange={onBasemapStyleChange}
          currentBasemapStyleId={currentBasemapStyleId}
          filteredBasemapStyles={filteredBasemapStyles}
        />
      )}
    </>
  );
};

export default Basemap;
