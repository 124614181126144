import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { noop } from '../utils/tools';
import appReducer, { appPersistConfig } from './appSlice';

let store = {};

const createPersistedReducer = (persistConfig, combinedReducer) => {
  if (!persistConfig) return combinedReducer;
  return persistReducer(persistConfig, combinedReducer);
};

function createReducerManager(initialReducers, rootPersistConfig) {
  // Create an object which maps keys to reducers
  const reducers = { ...initialReducers };

  // Create the initial rootReducer
  let rootReducer = Object.keys(reducers).length
    ? combineReducers(reducers)
    : noop;

  // An array which is used to delete state keys when reducers are removed
  let keysToRemove = [];

  return {
    getReducerMap: () => reducers,

    // The root reducer function exposed by this object
    // This will be passed to the store
    reduce: (state, action) => {
      // If any reducers have been removed, clean up their state first
      if (keysToRemove.length > 0) {
        state = { ...state };
        for (let key of keysToRemove) {
          delete state[key];
        }
        keysToRemove = [];
      }

      // Delegate to the combined reducer
      return createPersistedReducer(
        rootPersistConfig,
        rootReducer(state, action),
      );
    },

    // Adds a new reducer with the specified key
    add: (key, reducer) => {
      if (!key || reducers[key]) {
        return;
      }

      // Add the reducer to the reducer mapping
      reducers[key] = reducer;

      // Generate a new root reducer
      rootReducer = createPersistedReducer(
        rootPersistConfig,
        combineReducers(reducers),
      );
      store.replaceReducer(rootReducer);
    },

    // Removes a reducer with the specified key
    remove: (key) => {
      if (!key || !reducers[key]) {
        return;
      }

      // Remove it from the reducer mapping
      delete reducers[key];

      // Add the key to the list of keys to clean up
      keysToRemove.push(key);

      // Generate a new root reducer
      rootReducer = createPersistedReducer(
        rootPersistConfig,
        combineReducers(reducers),
      );
      store.replaceReducer(rootReducer);
    },
  };
}

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: [],
};

const staticReducers = {
  app: persistReducer(appPersistConfig, appReducer),
};

export const getCustomMiddleware = (middlewareConfigCallbacks) => {
  const config = {
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  };

  if (middlewareConfigCallbacks) {
    for (const middlewareConfigCallback of middlewareConfigCallbacks) {
      middlewareConfigCallback(config);
    }
  }

  return getDefaultMiddleware(config);
};

export default function configureAppStore(props) {
  const { middlewareConfigCallbacks } = props;

  const reducerManager = createReducerManager(
    staticReducers,
    rootPersistConfig,
  );
  store = configureStore({
    reducer: reducerManager.reduce,
    middleware: getCustomMiddleware(middlewareConfigCallbacks),
  });

  store.reducerManager = reducerManager;

  return store;
}

export const createPersistedStore = (store) => {
  return persistStore(store);
};
