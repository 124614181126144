import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { pathOr } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { POSITRON } from '@carto/react-basemaps';

// components
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

// utils
import { isArrayNotEmpty, isNilOrEmpty } from '@/utils/validator';

// styles
const useStyles = makeStyles((theme) => ({
  timeLineContainer: {
    position: 'absolute',
    bottom: theme.spacing(4),
    left: 'calc(364px + 5%)',
    right: 'calc(174px + 5%)',
    background: theme.palette.common.white,
    padding: '10px 20px',
    borderRadius: '4px',
  },
  iconButton: {
    color: theme.palette.common.black,
    '&:hover': {
      background: 'rgba(0,0,0,.3)',
    },
  },
}));

const TimelineMainContainer = styled.div`
  .MuiSlider-rail {
    background: ${(props) => props.theme.palette.common.black};
  }
  .MuiSlider-mark {
    height: 10px;
    width: 1px;
    background: ${(props) => props.theme.palette.common.black};
  }
  .MuiSlider-root {
    color: transparent;
    height: 2px;
  }
  .MuiSlider-thumb {
    background: ${(props) => props.theme.palette.common.black};
  }
  .timeline-title {
    font-size: 12px;
    color: ${(props) => props.theme.palette.common.black};
  }
  .MuiSlider-mark {
    width: 8px;
    background: transparent;
    transition: all 100ms ease-in;
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 1px;
      height: 100%;
      background: ${(props) => props.theme.palette.common.black};
    }
    &:hover {
      height: 100%;
    }
  }
`;

const Timeline = (props) => {
  const classes = useStyles(props);
  const theme = useTheme();

  const [value, setValue] = useState(0);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [markId, setMarkId] = useState(null);

  const basemap = useSelector((state) => state.carto.basemap) || POSITRON;

  let timestamps = pathOr([], ['timestamps'], props);
  const timeLineTitle = pathOr('', ['timeLineTitle'], props);
  const currentTimestamp = pathOr('', ['currentTimestamp'], props);
  const onItemSelected = pathOr(() => {}, ['onItemSelected'], props);

  // handles the slider change
  const handleSliderChange = (event, newValue) => {
    if (!isNilOrEmpty(newValue)) {
      onItemSelected(timestamps[newValue]);
    }
  };

  // sets the slider value
  const setSliderValue = (event, newValue) => {
    if (!isNilOrEmpty(newValue)) {
      setValue(newValue);
    }
  };

  // handles the slider click event
  const handleSliderClick = (type, newValue) => {
    switch (type) {
      case 'increment':
        if (newValue >= timestamps.length - 1) {
          setValue(timestamps.length - 1);
        } else {
          setValue((newValue += 1));
        }
        break;
      case 'decrement':
        if (newValue <= 0) {
          setValue(0);
        } else {
          setValue((newValue -= 1));
        }
        break;
    }
    onItemSelected(timestamps[newValue]);
  };

  // return a tooltip with the date values
  const valueLabelFormat = (value) => timestamps[value]?.timeStampDates;

  useEffect(() => {
    const defaultValue =
      isArrayNotEmpty(timestamps) && !isNilOrEmpty(currentTimestamp)
        ? timestamps.findIndex(
            (timestamp) =>
              timestamp?.timeStampId === currentTimestamp?.timeStampId,
          )
        : 0;
    if (!isNilOrEmpty(defaultValue) && defaultValue >= 0) {
      setValue(defaultValue);
    } else {
      setValue(0);
    }
  }, [isArrayNotEmpty(timestamps) && !isNilOrEmpty(currentTimestamp)]);

  const markElements = document.querySelectorAll(
    '.timeline-container .MuiSlider-root .MuiSlider-mark',
  );
  const setTooltip = (index) => {
    return () => {
      setTooltipIsOpen(true);
      setMarkId(index);
    };
  };
  useEffect(() => {
    if (isArrayNotEmpty(Array.from(markElements))) {
      for (const markElement of Array.from(markElements)) {
        const index = markElement?.getAttribute('data-index') || null;
        markElement.addEventListener('mouseover', setTooltip(index));
      }
    }
    () => {
      for (const markElement of Array.from(markElements)) {
        markElement.addEventListener('mouseover', setTooltip);
      }
    };
  }, [markElements]);

  return (
    isArrayNotEmpty(timestamps) && (
      <TimelineMainContainer
        theme={theme}
        basemap={basemap}
        className={classNames(classes.timeLineContainer, 'timeline-container')}
      >
        <Box>
          <Typography className={'timeline-title'}>
            {timeLineTitle}
            <strong>{valueLabelFormat(value)}</strong>
          </Typography>
          <Stack spacing={1} direction='row' alignItems='center'>
            <IconButton
              className={classes.iconButton}
              aria-label='Decrease date'
              onClick={() => {
                handleSliderClick('decrement', value);
              }}
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
            <Tooltip
              arrow
              open={tooltipIsOpen}
              onOpen={() => setTooltipIsOpen(true)}
              onClose={() => setTooltipIsOpen(false)}
              title={valueLabelFormat(markId)}
              placement='top'
              followCursor
            >
              <Slider
                aria-label='Timestamps'
                step={1}
                value={value}
                onChange={setSliderValue}
                onChangeCommitted={handleSliderChange}
                min={0}
                max={timestamps.length - 1}
                marks={true}
                getAriaValueText={valueLabelFormat}
              />
            </Tooltip>

            <IconButton
              aria-label='Increase date'
              className={classes.iconButton}
              onClick={() => {
                handleSliderClick('increment', value);
              }}
            >
              <AddCircleIcon />
            </IconButton>
          </Stack>
        </Box>
      </TimelineMainContainer>
    )
  );
};

export default Timeline;
