import React from 'react';

const Secure = () => {
  return (
    <svg
      width='12'
      height='16'
      viewBox='0 0 12 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6 0.666504L0 3.33317V7.33317C0 11.0332 2.56 14.4932 6 15.3332C9.44 14.4932 12 11.0332 12 7.33317V3.33317L6 0.666504ZM6 7.99317H10.6667C10.3133 10.7398 8.48 13.1865 6 13.9532V7.99984H1.33333V4.19984L6 2.1265V7.99317Z'
        fill='#2579DD'
      />
    </svg>
  );
};

export default Secure;
