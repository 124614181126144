import { anyPass, has, is, isEmpty, isNil } from 'ramda';

export { isEmpty, anyPass, isNil };

/**
 * Checks if an object has a specific property
 *
 * @param {Object} object  - the object to be verified
 * @param {String} property - the string property of the object
 * @returns {Boolean} - true or false depending on the condition
 */
export const checkObjectProperty = (object, property) => {
  return object && has(property, object) ? object[property] : undefined;
};

/**
 * Checks if array is not empty
 *
 * @param {Array} value - the array argument
 * @returns {Boolean} - false or true, depending on the value of the array
 */

export const isArrayNotEmpty = (value = []) =>
  Array.isArray(value) && value.length > 0;

/**
 * Returns `true` if the given value is its type's empty value, `null` or `undefined`.
 *
 * @func isNilOrEmpty
 * @memberOf Validator
 * @category Validator
 * @sig * -> Boolean
 * @param {*} val The value to test
 * @return {Boolean}
 * @see {@link http://ramdajs.com/docs/#isEmpty|isEmpty}, {@link http://ramdajs.com/docs/#isNil|isNil}
 * @example
 *
 * Validator.isNilOrEmpty([1, 2, 3]); //=> false
 * Validator.isNilOrEmpty([]); //=> true
 * Validator.isNilOrEmpty(''); //=> true
 * Validator.isNilOrEmpty(null); //=> true
 * Validator.isNilOrEmpty(undefined): //=> true
 * Validator.isNilOrEmpty({}); //=> true
 * Validator.isNilOrEmpty({length: 0}); //=> false
 */
export const isNilOrEmpty = anyPass([isNil, isEmpty]);

/**
 * Checks if all props in a object exists in another
 * @param {Object} objModel
 * @param {Object} objToCompare
 * @return {Boolean} - true of false depending on the condition
 */
export const allObjectPropsExists = (objModel = {}, objToCompare = {}) => {
  return (
    is(Object, objModel) &&
    is(Object, objToCompare) &&
    Object.keys(objModel).every((key) => has(key, objToCompare))
  );
};
