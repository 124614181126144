// Default client
let client = 'c4react';

export function getClient() {
  return client;
}

export function setClient(c) {
  client = c;
}
