import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Header from '../../components/Header/Header';
import { Loader } from '../../components/loader';
import useEnvs from '../../hooks/calls/useEnvs';
import { useSession } from '../../context/session';
import { randomString } from '../../utils/tools';
import './style.css';

const IFrame = (props) => {
  const { srcKey, defaultSrcKey, title, name, id } = props;
  const _title = title !== undefined ? title : 'iframe';
  const _name = name !== undefined ? name : 'iframe-' + randomString(10);
  const _id = id !== undefined ? id : _name;

  const { getBearerToken } = useSession();
  const { data, isLoading } = useEnvs();
  const currentEnv = useSelector((state) => state.app.currentEnv);
  const [srcLink, setSrcLink] = useState('');
  const getSrcLink = async () => {
    if (data) {
      let srcUri = '';
      const envItem = data.find((env) => env.id === currentEnv);
      if (!envItem) {
        srcUri = data[0][srcKey] || data[0][defaultSrcKey];
      } else {
        srcUri = envItem[srcKey] || envItem[defaultSrcKey];
      }
      try {
        const url = new URL(srcUri);

        // TODO this should be controlled by the calling component
        //url.search = `?token=${await getBearerToken()}&embedded=True`;

        setSrcLink(url.href);
      } catch (e) {
        console.debug('Not a valid URL:', srcUri);
        setSrcLink('');
      }
    }
  };

  useEffect(() => {
    getSrcLink();
  }, [data, currentEnv]);

  if (isLoading) {
    return <Loader></Loader>;
  }

  return (
    <div>
      <Header />
      {srcLink !== '' && (
        <iframe
          title={_title}
          name={_name}
          id={_id}
          frameBorder='0'
          border='0'
          cellSpacing='0'
          scrolling='auto'
          style={{
            height: '100%',
            width: '100%',
            backgroundColor: 'white',
            position: 'fixed',
          }}
          src={srcLink}
        />
      )}
    </div>
  );
};

IFrame.defaultProps = {
  defaultSrcKey: 'embedLink',
  title: 'iframe',
};

export default IFrame;
