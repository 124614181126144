import { MVTLayer } from '@deck.gl/geo-layers';
import { MAX_ZOOM_LEVEL } from '@/utils/constants';

// MVTLayer componenet
const MVTLayerComponent = (url = '', { layersInfo, ...rest } = viewState) => {
  return new MVTLayer({
    data: url,
    minZoom: 0,
    maxZoom: MAX_ZOOM_LEVEL,
    ...rest,
    ...layersInfo,
  });
};
export default MVTLayerComponent;
