import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { useEnv } from './context/env.context';
import { Error } from './pages/error';

export const Auth0ProviderWithHistory = ({ children }) => {
  const { domain, clientId, audience } = useEnv();

  const redirectUri = new URL(window.location.origin);
  const queryParams = new URLSearchParams(window.location.search);
  const error = queryParams.get('error');
  const isUnauthorized = error?.toLowerCase() === 'unauthorized';

  const onRedirectCallback = (appState) => {};

  // Handle unauthorized login
  if (isUnauthorized) {
    const errorDescription = queryParams.get('error_description');
    return <Error title={error} description={errorDescription} />;
  }

  if (
    process.env.PUBLIC_URL !== '/' &&
    process.env.NODE_ENV &&
    process.env.NODE_ENV !== 'development'
  ) {
    // always burn in so that Auth0 redirect URI can forward correctly
    global.window.sessionStorage.setItem(
      'auth0RedirectTo',
      window.location.href,
    );
  }

  if (!(domain && clientId && audience)) {
    return null;
  }

  // make sure to carry forward the current route
  redirectUri.hash = window.location.hash;

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={redirectUri.toString()}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
