import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

/**
 * Utility component to render dompurify sanitized html. This is useful when there are bits
 * of html in i18n.json which should not be broken apart.
 *
 * @param {object} props the props
 * @param {import('react').ReactElementType} props.elementType the element type
 * @param {object} props.elementProps the element props
 * @param {string} props.html the html to be rendered
 * @returns {import('react').ReactElement} a ReactElement
 */
export default function DOMPurifiedHTML({
  elementType: ElementType,
  elementProps,
  html,
}) {
  const sanitizedHTML = DOMPurify.sanitize(html, {
    USE_PROFILES: { html: true },
  });
  return (
    <ElementType
      {...elementProps}
      dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
    />
  );
}

DOMPurifiedHTML.propTypes = {
  elementProps: PropTypes.object,
  elementType: PropTypes.elementType.isRequired,
  html: PropTypes.string.isRequired,
};

DOMPurifiedHTML.defaultProps = {
  elementProps: {},
};
