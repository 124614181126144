import React from 'react';
import { Upload } from 'antd';
import UploadIcon from '../icons/Upload';
const { Dragger } = Upload;

const DraggerProps = {
  // loading: true,
  name: 'file',
  multiple: true,

  onDrop(e) {},
};

const App = (props) => {
  const { onSetFiles, isUploading } = props;
  const onChange = (info) => {
    onSetFiles(info.fileList);
  };

  return (
    <Dragger
      {...DraggerProps}
      multiple={true}
      onChange={onChange}
      beforeUpload={() => {
        return false;
      }}
      disabled={isUploading ? true : false}
      style={{ opacity: isUploading ? 0.4 : 1 }}
    >
      <p className='ant-upload-drag-icon'>
        <UploadIcon />
      </p>
      <p className='ant-upload-text'>
        Click or drag file to this area to upload
      </p>
      <p className='ant-upload-hint'>Support for a single or bulk upload.</p>
    </Dragger>
  );
};

export default App;
