import Spinner from '../Loading/Spinner';
import useEnvs from '../../hooks/calls/useEnvs';
import { useSelector } from 'react-redux';

const SelectEnv = () => {
  const currentEnv = useSelector((state) => state.app.currentEnv);
  const { data: envList, isLoading: isEnvListLoading } = useEnvs();

  if (isEnvListLoading) return <Spinner />;

  return (
    <div style={{ marginLeft: 11, marginRight: 11 }}>
      {(envList?.find((env) => env.id === currentEnv) || envList[0]).name}
    </div>
  );
};

export default SelectEnv;
