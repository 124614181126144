import { useState } from 'react';
import { Form, Input } from 'antd';
import { Collapse } from 'antd';
import Text from '../Text';
import ArrowIcon from '../icons/Arrow';
import styled from 'styled-components';
import Spinner from '../Loading/Spinner';
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';

const Wrapper = styled.div`
  border-bottom: 1px solid #d1d5da;
`;
const { Panel } = Collapse;

const Header = (props) => {
  const { label, isOpen, isLoading, isUploaded, hadErrorInUploading } = props;
  return (
    <div className='flex-b-c w-100-p'>
      <Text className={'text18'}>{label}</Text>
      <div className='flex-c-c'>
        {isLoading && <Spinner />}
        {isUploaded && (
          <CheckCircleOutlined style={{ color: '#22863A', fontSize: 14 }} />
        )}
        {hadErrorInUploading && (
          <CloseCircleOutlined style={{ color: '#D73A49', fontSize: 14 }} />
        )}
        <ArrowIcon direction={isOpen ? 'up' : 'down'} className='ml-14' />
      </div>
    </div>
  );
};
const UploadInput = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [progress, setProgress] = useState(false);
  const {
    file,
    onSetDescription,
    isLoading,
    isUploaded,
    hadErrorInUploading,
    onSetTitle,
    errors,
  } = props;
  const [form] = Form.useForm();

  // useEffect(() => {
  //   if (!!url && !isUploaded) {
  //     uploadFile();
  //   }
  // }, [url, isUploaded]);
  return (
    <Wrapper>
      <Collapse
        // expandIcon={() => <Header />}
        expandIconPosition={'end'}
        bordered={false}
        ghost
        onChange={() => {
          setIsOpen((prev) => !prev);
        }}
      >
        <Panel
          header={
            <Header
              label={file.name}
              isOpen={isOpen}
              isLoading={isLoading}
              isUploaded={isUploaded}
              hadErrorInUploading={hadErrorInUploading}
            />
          }
          showArrow={false}
        >
          <Form form={form} layout='vertical'>
            <Form.Item
              name='title'
              label={'Title'}
              rules={[{ required: true, message: 'Please input the title!' }]}
              validateStatus={errors?.title ? 'error' : 'validating'}
              help={errors?.title}
            >
              <Input
                placeholder='Title'
                onChange={(e) => {
                  onSetTitle(file.uid, e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item name='description' label={'Description:'}>
              <Input
                placeholder='Description:'
                onChange={(e) => {
                  onSetDescription(file.uid, e.target.value);
                }}
              />
            </Form.Item>
          </Form>
          {/* {progress} */}
          {isLoading && <div>loading</div>}
        </Panel>
      </Collapse>
    </Wrapper>
  );
};

export default UploadInput;

// const App = () => (
//   <Collapse
//     defaultActiveKey={["1"]}
//     ghost
//     expandIconPosition={"end"}
//     // expandIcon={() => <div>up</div>}
//   >
//     <Panel header="This is panel header 1" key="1">
//       <p>{text}</p>
//     </Panel>
//     <Panel header="This is panel header 2" key="2">
//       <p>{text}</p>
//     </Panel>
//     <Panel header="This is panel header 3" key="3">
//       <p>{text}</p>
//     </Panel>
//   </Collapse>
// );
// export default App;
