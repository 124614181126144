/**
 * basemap configuration file used for rendering the Basemap selector
 * component
 */

// carto images
import CartoDarkMatterIcon from '@/assets/img/basemaps/carto-dark-matter.png';
import CartoPositronIcon from '@/assets/img/basemaps/carto-positron.png';
import CartoVoyagerIcon from '@/assets/img/basemaps/carto-voyager.png';

// mapbox default
import MapboxSatelliteStreets from '@/assets/img/basemaps/mapbox-satellite-streets.png';
import MapboxSatellite from '@/assets/img/basemaps/mapbox-satellite.png';
import DefaultBasemap from '@/assets/img/basemaps/default-basemap.png';

// carto
import { BASEMAPS, DARK_MATTER, POSITRON } from '@carto/react-basemaps';

import {
  MAP_STYLE_TYPE,
  MAPBOX_MAPS,
  CLASSIC_MAPBOX_MAP_TYPE,
} from './constants';

export const MAP_STYLES = [
  {
    type: MAP_STYLE_TYPE.MAPBOX,
    id: 'satellite',
    label: 'Satellite',
    url: 'mapbox://styles/mapbox/satellite-v9',
    icon: MapboxSatellite,
    options: {
      mapType: MAPBOX_MAPS.MAPBOX,
    },
  },
  {
    type: MAP_STYLE_TYPE.MAPBOX,
    id: 'satellite-streets',
    label: 'Satellite streets',
    url: 'mapbox://styles/mapbox/satellite-streets-v12',
    icon: MapboxSatelliteStreets,
    options: {
      mapType: MAPBOX_MAPS.MAPBOX,
    },
  },
  {
    type: MAP_STYLE_TYPE.MAPBOX,
    id: 'positron',
    label: 'Positron',
    url: BASEMAPS.positron.options.mapStyle,
    icon: CartoPositronIcon,
    options: {
      mapType: MAPBOX_MAPS.MAPLIBRE,
    },
  },
  {
    type: MAP_STYLE_TYPE.MAPBOX,
    id: 'dark-matter',
    label: 'Dark matter',
    url: BASEMAPS['dark-matter'].options.mapStyle,
    icon: CartoDarkMatterIcon,
    options: {
      mapType: MAPBOX_MAPS.MAPLIBRE,
    },
  },
  {
    type: MAP_STYLE_TYPE.MAPBOX,
    id: 'voyager',
    label: 'Voyager',
    url: BASEMAPS.voyager.options.mapStyle,
    icon: CartoVoyagerIcon,
    options: {
      mapType: MAPBOX_MAPS.MAPLIBRE,
    },
  },
];

export const MAPS_STYLES_WITH_WHITE_LABEL = [
  DARK_MATTER,
  CLASSIC_MAPBOX_MAP_TYPE.SATELLITE,
  CLASSIC_MAPBOX_MAP_TYPE.SATELLITE_STREETS,
];

export const DEFAULT_MAP_STYLE = POSITRON;
export const DEFAULT_MAP_TYPE = MAP_STYLE_TYPE.MAPBOX;
export { MAP_STYLE_TYPE as MAP_STYLE_TYPE };
export { MAPBOX_MAPS as MAPBOX_MAPS };
export { DefaultBasemap as DEFAULT_BASEMAP_ICON };
