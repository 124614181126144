import { views } from '../constants/env';

const config = {
  demandIntelService: process.env.REACT_APP_DEMAND_INTEL_SERVER,
  graphQlRoot: process.env.REACT_APP_GRAPH_QL_ROOT,
  apiRoutes: {
    datasetList: '/v1/datasets',
    saveProject: '/v1/project/upload/',
    readProjectConfig: '/v1/project/',
    getOrgConfig: '/v1/organization/config',
    getOrganizationLogo: '/v1/organization/resources/logo.png',
    getSignedUrl: '/v1/project',
    fileUpload: '/v1/file-upload/get-links',
    addEnv: '/v1/environments/config',
    getEnvList: '/v1/environments/config',
    getSingleEnv: '/v1/project',
    getProjectView: (envId, viewId) => {
      const viewType = viewId === views.MY_VIEW ? 'for-me' : 'for-team';
      return `/v1/projects/${envId}/visualizations/${viewType}`;
    },
    postProjectView: (envId, viewId) => {
      const viewType = viewId === views.MY_VIEW ? 'for-me' : 'for-team';
      return `/v1/projects/${envId}/visualizations/${viewType}`;
    },
    postReplaceMyView: (envId) => {
      return `/v1/projects/${envId}/visualizations/update-my-view`;
    },
  },
};

export default config;
