import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const sizes = {
  small: 12,
  medium: 24,
};

const Spinner = (props) => {
  const { size = 'small' } = props;
  return (
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: sizes[size] }} spin />}
    />
  );
};

export default Spinner;
