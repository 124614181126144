import React from 'react';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const demandIntelService = process.env.REACT_APP_DEMAND_INTEL_SERVER;

const isEnvValid = domain && clientId && audience;

if (!isEnvValid) {
  throw new Error('Missing environment variables.');
}

const dotenv = {
  domain: domain,
  clientId: clientId,
  audience: audience,
  demandIntelService: demandIntelService,
};

export const EnvContext = React.createContext(dotenv);

export const useEnv = () => {
  const context = React.useContext(EnvContext);
  if (!context) {
    throw new Error(`useEnv must be used within a EnvProvider`);
  }
  return context;
};

export const EnvProvider = (props) => {
  return <EnvContext.Provider value={dotenv} {...props} />;
};
