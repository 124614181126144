import React from 'react';

const Select = () => {
  return (
    <svg
      width='8'
      height='12'
      viewBox='0 0 8 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.99994 1.88667L6.11328 4L7.05328 3.06L3.99994 0L0.939941 3.06L1.88661 4L3.99994 1.88667ZM3.99994 10.1133L1.88661 8L0.946608 8.94L3.99994 12L7.05994 8.94L6.11328 8L3.99994 10.1133Z'
        fill='#24292E'
      />
    </svg>
  );
};

export default Select;
