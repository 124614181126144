import React, { useState } from 'react';
import axios from 'axios';
import globalConfig from '../../utils/config';
import { useEnv } from '../../context/env.context';
import GlobalLoading from '../GlobalLoading';
import { Modal, message } from 'antd';
import FileUpload from '../FileUpload';
import Text from '../Text';
import UploadInput from './UploadInput';
import { v4 as uuidv4 } from 'uuid';
import UploadDescription from './UploadDescription';
import AddButton from '../Buttons/AddButton';
import { useSession } from '../../context/session';
import useGuards from '../../hooks/useGuards';

const UploadData = () => {
  const { demandIntelService } = useEnv();
  const { getBearerToken } = useSession();
  const { hasPermission } = useGuards();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [files, setFiles] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const [titles, setTitles] = useState([]);
  const [errors, setErrors] = useState([]);
  const [loadings, setLoadings] = useState([]);
  const [uploadedList, setUploadedList] = useState([]);
  const [uploadingErrors, setUploadingErrors] = useState([]);

  const onSetFiles = (fileList) => {
    setFiles(fileList.map((file) => ({ ...file, uid: uuidv4() })));
  };
  const onSetUploadingErrors = (uid, hasError) => {
    setUploadingErrors((prev) => {
      const exist = prev.find((item) => item.uid === uid);
      if (exist) {
        return prev.map((item) => {
          return item.uid === uid
            ? {
                uid,
                hasError,
              }
            : item;
        });
      } else return [...prev, { uid, hasError }];
    });
  };

  const onSetUploadedList = (uid, isUploaded) => {
    setUploadedList((prev) => {
      const exist = prev.find((item) => item.uid === uid);
      if (exist) {
        return prev.map((item) => {
          return item.uid === uid
            ? {
                uid,
                isUploaded,
              }
            : item;
        });
      } else return [...prev, { uid, isUploaded }];
    });
  };

  const onSetLoading = (uid, loading) => {
    setLoadings((prev) => {
      const exist = prev.find((item) => item.uid === uid);
      if (exist) {
        return prev.map((item) => {
          return item.uid === uid
            ? {
                uid,
                loading,
              }
            : item;
        });
      } else return [...prev, { uid, loading }];
    });
  };

  const uploadFile = async (id, url) => {
    const file = files.find((item) => item.uid === id);
    const config = {
      url,
      method: "PUT",
      data: file.originFileObj,
      headers: { 
        'Content-Type': 'application/octet-stream',
      },
    };
    try {
      onSetLoading(file.uid, true);
      const res = await axios(config);
      message.success(`${file.name} was uploaded successfully`);
      onSetLoading(file.uid, false);
      onSetUploadedList(file.uid, true);
    } catch (e) {
      console.error(e.message);
      onSetLoading(file.uid, false);
      onSetUploadingErrors(file.uid, true);
    }
  };
  const onSetTitle = (uid, title) => {
    const hasExist = titles.find((item) => item.uid === uid);
    if (hasExist) {
      setTitles((prev) => {
        return prev.map((item) => {
          return item.uid === uid
            ? {
                uid,
                title,
              }
            : item;
        });
      });
    } else {
      setTitles((prev) => [...prev, { uid, title }]);
    }
  };
  /*
  const onSetError = (uid, field, message) => {
    const hasExist = errors.find((item) => item.uid === uid);
    if (hasExist) {
      setErrors((prev) => {
        return prev.map((item) => {
          return item.uid === uid
            ? {
                uid,
                [field]: message,
              }
            : item;
        });
      });
    } else {
      setErrors((prev) => [...prev, { uid, [field]: message }]);
    }
  };
  */
  const onSetDescription = (uid, description) => {
    // setDescription((...prev)=>{return [...]})
    const hasExist = descriptions.find((item) => item.uid === uid);
    if (hasExist) {
      setDescriptions((prev) => {
        return prev.map((item) => {
          return item.uid === uid
            ? {
                uid,
                description,
              }
            : item;
        });
      });
    } else {
      setDescriptions((prev) => [...prev, { uid, description }]);
    }
  };
  const showModal = () => {
    setIsModalVisible(true);
  };
  const validate = () => {
    let hasError = false;
    /*
    files.forEach((file) => {
      const titleForThisFile = titles.find((item) => item.uid === file.uid);
      if (!titleForThisFile || !titleForThisFile.title) {
        onSetError(file.uid, "title", "Please enter title");
        hasError = true;
      }
    });
    */
    if (hasError) {
      return false;
    } else {
      setErrors([]);

      return true;
    }
  };
  const handleOk = async () => {
    const res = validate();
    if (!res) return;
    const payload = files
      .filter((file) => {
        const uploadedBefore = uploadedList.find(
          (item) => item.uid === file.uid,
        );
        if (uploadedBefore) return false;
        return true;
      })
      .map((item) => {
        return {
          id: item.uid,
          name: item.name,
          description:
            descriptions.find((desc) => desc.uid === item.uid)?.description ||
            '',
          title: titles.find((desc) => desc.uid === item.uid)?.title || '',
        };
      });
    if (payload.length === 0) return;
    try {
      const getSignedUrl = `${demandIntelService}${globalConfig.apiRoutes.fileUpload}`;
      const configGet = {
        url: getSignedUrl,
        method: 'POST',
        data: { payload },
        headers: {
          Authorization: `Bearer ${await getBearerToken()}`,
        },
      };
      setIsLoading(true);

      const res = await axios(configGet);
      setIsLoading(false);

      for (let i in res.data.data) {
        uploadFile(res.data.data[i].id, res.data.data[i].url);
      }
    } catch (e) {
      console.error(e.message);
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setFiles([]);
  };

  if (!hasPermission('di:FileUpload:create')) {
    return <></>;
  }

  return (
    <>
      <AddButton type={'upload'} onClick={showModal} disable={false}>
        Upload data
      </AddButton>
      <Modal
        width={576}
        okText={'Upload'}
        title={
          <div>
            <Text className={'text15'}>
              Upload data to Aperture Intelligence
            </Text>
            <Text className={'text16'}>
              Securely upload data to our platform for further analysis or to
              view your own data on the demand intelligence platform.
            </Text>
          </div>
        }
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
      >
        <UploadDescription />
        <FileUpload onSetFiles={onSetFiles} isUploading={false} />
        <div style={{ marginBottom: 16 }} />
        {isLoading && <GlobalLoading />}

        {files.map((file, index) => {
          return (
            <div key={file.uid}>
              <UploadInput
                file={file}
                onSetDescription={onSetDescription}
                onSetTitle={onSetTitle}
                isLoading={
                  loadings.find((item) => item.uid === file.uid)?.loading ||
                  false
                }
                isUploaded={
                  uploadedList.find((item) => item.uid === file.uid)
                    ?.isUploaded || false
                }
                hadErrorInUploading={
                  uploadingErrors.find((item) => item.uid === file.uid)
                    ?.hasError || false
                }
                errors={errors.find((item) => item.uid === file.uid) || false}
              />
            </div>
          );
        })}
      </Modal>
    </>
  );
};

export default UploadData;
