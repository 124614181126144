import React from 'react';
import styled, { css } from 'styled-components';
const Wrapper = styled.div`
  border-radius: 2px;
  padding: 1px 8px;
  font-size: 12px;
  line-height: 20px;
  width: 66px;
  position: absolute;
  top: 10px;
  right: 7px;
  ${(props) => {
    switch (props.status) {
      case 'ACTIVE':
        return css`
          background: #f0fff4;
          border: 1px solid #bef5cb;
          color: #28a745;
        `;
      case 'ARCHIVED':
      case 'IN_REVIEW':
        return css`
          background: #fff3f0;
          border: 1px solid #ffb1a3;
          color: #e12e23;
        `;
      default:
        break;
    }
  }}
`;

const Label = (props) => {
  const { status } = props;

  const getStatusText = () => {
    if (status === 'ACTIVE') return 'Active';
    if (status === 'ARCHIVED') return 'Archived';
    if (status === 'IN_REVIEW') return 'Review';
    return '';
  };
  return <Wrapper status={status}>{getStatusText()}</Wrapper>;
};

export default Label;
