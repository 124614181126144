import { QueryClient, QueryClientProvider } from 'react-query';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import './App.css';
import { ProtectedRoute } from './components/protected-route';
import Dashboard from './pages/Dashboard/Dashboard';
import Documentation from './pages/Documentation/Documentation';
import Files from './pages/Files/Files';
import Carto from './pages/Carto';
import { NotFound } from './pages/not-found';
import Profile from './pages/Profile';
import YourEnvironments from './pages/YourEnvironments';
import paths from './constants/paths';
import EnvironmentsManagement from './components/EnvironmentsManagement';
import { ApolloWrapper } from './ApolloWrapper';
import { ReactQueryDevtools } from 'react-query/devtools';
import { IntlProvider } from 'react-intl';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 12 * 60 * 60 * 1000, // 12 hours in milliseconds
      cacheTime: 24 * 60 * 60 * 1000, // 24 hours in milliseconds
      retry: true,
      retryDelay: (attempt, error) =>
        Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

function App() {
  return (
    <IntlProvider locale='en'>
      <QueryClientProvider client={queryClient}>
        <ApolloWrapper>
          <Router>
            <div className='App'>
              <EnvironmentsManagement />
              <Switch>
                <ProtectedRoute
                  exact
                  path={paths.yourEnvironments}
                  component={YourEnvironments}
                />
                <ProtectedRoute
                  exact
                  path={paths.documentation}
                  component={Documentation}
                />
                <ProtectedRoute
                  exact
                  path={paths.dashboard}
                  component={Dashboard}
                />
                <ProtectedRoute exact path={paths.files} component={Files} />
                <ProtectedRoute
                  exact
                  path={paths.profile}
                  component={Profile}
                />
                <ProtectedRoute
                  exact
                  path={paths.visualization}
                  component={Carto}
                />
                <Route path='*' component={NotFound} />
              </Switch>
            </div>
          </Router>
        </ApolloWrapper>
        {/*
          process.env.NODE_ENV &&
          process.env.NODE_ENV === 'development' &&
          <ReactQueryDevtools />
        */}
      </QueryClientProvider>
    </IntlProvider>
  );
}

export default App;
