import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';

import SelectIcon from '../icons/Select';
import { setProjectView } from '../../store/appSlice';
import { views } from '../../constants/env';

const { Option } = Select;

const SelectProjectView = () => {
  const dispatch = useDispatch();
  const projectView = useSelector((state) => state.app.projectView);

  const handleViewChange = (viewId) => {
    dispatch(setProjectView(viewId));
  };

  return (
    <div>
      <Select
        style={{
          width: 120,
        }}
        value={projectView || views.MY_VIEW}
        onChange={handleViewChange}
        bordered={false}
        suffixIcon={<SelectIcon />}
        placeholder='Views'
        optionLabelProp='label'
        dropdownMatchSelectWidth={false}
      >
        <Option key={views.MY_VIEW} value={views.MY_VIEW} label='my view'>
          Load my view
        </Option>
        <Option key={views.TEAM_VIEW} value={views.TEAM_VIEW} label='team view'>
          Load team view
        </Option>
      </Select>
    </div>
  );
};

export default SelectProjectView;
