import configureAppStore, { createPersistedStore } from './utils';
import { includeCartoConfigToMiddleware, addCartoToStore } from './Carto';

const store = configureAppStore({
  middlewareConfigCallbacks: [includeCartoConfigToMiddleware],
});
addCartoToStore(store);

export default store;

export const persistor = createPersistedStore(store);
