import { createCartoSlice } from '@carto/react-redux';
import { initialState } from './initialStateSlice';
import merge from 'lodash.merge';

export const includeCartoConfigToMiddleware = (config) => {
  const devConfig = {
    immutableCheck: {
      ignoredPaths: ['carto.viewportFeatures'],
    },
    serializableCheck: {
      ignoredPaths: ['carto.viewportFeatures'],
      ignoredActions: ['carto/setViewportFeatures'],
    },
  };
  const prodConfig = {
    immutableCheck: false,
    serializableCheck: false,
  };

  const isProductionEnv = process.env.NODE_ENV === 'production';
  const cartoConfig = isProductionEnv ? prodConfig : devConfig;

  return merge(config, cartoConfig);
};

export const addCartoToStore = (store) => {
  const cartoReducer = createCartoSlice(initialState);
  const reducer = (state, action) => {
    if (action.type == 'carto/reset') {
      state = undefined;
    }

    return cartoReducer(state, action);
  };

  store.reducerManager.add('carto', reducer);
};

export const reset = () => ({ type: 'carto/reset' });
