import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';

import Header from '../../components/Header/Header';
import Text from '../../components/Text';
import ProjectCard from './ProjectCard';
import Resources from './Resources';
import GlobalLoading from '../../components/GlobalLoading';
import useEnvs from '../../hooks/calls/useEnvs';
import { setCurrentEnv } from '../../store/appSlice';
import { views } from '../../constants/env';
import store from '../../store';
import { setProjectView, reset as resetApp } from '../../store/appSlice';
import { addCartoToStore, reset as resetCarto } from '../../store/Carto';
import { redirectIfAnyCartoProject } from '../../utils/redirect';

const CardWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

const YourEnvironments = () => {
  const { data: envList, isLoading: isEnvListLoading } = useEnvs();
  const [envReset, setEnvReset] = useState(false);
  const dispatch = useDispatch();
  const { user } = useAuth0();

  useEffect(() => {
    if (envReset) return;

    dispatch(resetApp());
    dispatch(resetCarto());
    setEnvReset(true);
  }, [envReset]);

  useEffect(() => {
    if (!envList) return;
    redirectIfAnyCartoProject(envList);
  }, [envList]);

  const setEnvironment = (envId) => {
    dispatch(setCurrentEnv(envId));
    dispatch(setProjectView(views.MY_VIEW));
  };

  if (isEnvListLoading) {
    return (
      <>
        <GlobalLoading />
      </>
    );
  }

  return (
    <div>
      <Header page='environments' />
      <div className='p-48'>
        <Text className='text19 mb-24'>Hello, {user.name}</Text>
        <Text className='text15 mb-4'>Your Environments</Text>
        <Text className='text16 mb-16'>
          See all of your team projects below
        </Text>
        <CardWrapper className='project-list-container'>
          {envList?.map((env) => {
            return (
              <ProjectCard
                key={env.id}
                env={env}
                setEnvironment={setEnvironment}
              />
            );
          })}
        </CardWrapper>
      </div>
      <Resources />
    </div>
  );
};

export default YourEnvironments;
