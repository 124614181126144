import React from 'react';
import { SvgIcon } from '@mui/material';

export default function OpacityIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM17.625 19V17.625H19V15.375H17.625V13.125H19V10.875H17.625V8.625H19V6.375H17.625V5H15.375V6.375H13.125V5H10.875V6.375H8.625V5H6.375V6.375H5V8.625H6.375V10.875H5V13.125H6.375V15.375H5V17.625H6.375V19H8.625V17.625H10.875V19H13.125V17.625H15.375V19H17.625ZM15.375 15.375H17.625V17.625H15.375V15.375ZM13.125 15.375H15.375V13.125H17.625V10.875H15.375V8.625H17.625V6.375H15.375V8.625H13.125V6.375H10.875V8.625H8.625V6.375H6.375V8.625H8.625V10.875H6.375V13.125H8.625V15.375H6.375V17.625H8.625V15.375H10.875V17.625H13.125V15.375ZM13.125 13.125H15.375V10.875H13.125V8.625H10.875V10.875H8.625V13.125H10.875V15.375H13.125V13.125ZM13.125 13.125H10.875V10.875H13.125V13.125Z'
      />
    </SvgIcon>
  );
}
